import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { GatsbyImage } from "gatsby-plugin-image"

export const IndexPageTemplate = ({image, title, description }) => {
  return (
    <div>
    <section className="section visuallyhidden">
      <div className="container is-max-widescreen">
        <h1>
          {title}
        </h1>
      </div>
    </section>
    <section className="section">
      <div className="container is-max-widescreen">
        <div className="columns is-centered is-vcentered">
          <div className="column is-half has-text-centered">
            <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                /> 
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}


IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
}


const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        description={frontmatter.description}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      title
      image {
        childImageSharp {
          gatsbyImageData(
            height: 880, 
            formats: [AUTO, WEBP])
        }
      }
      description
    }
  }
}
`
